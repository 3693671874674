import './Footer.css';

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WhatsappContactButton from '../contact/WhatsappContactButton';


function Footer() {
    // const location = useLocation();
    const { t } = useTranslation();
    // const isContactPage = location.pathname === '/contact';

    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer mt-auto pt-5">
            <div className="d-flex justify-content-center align-items-center border-bottom pb-2 mb-2">
                <WhatsappContactButton />
                <Link to="/" className="text-muted ms-3"  style={{ textDecoration: 'none', color: 'inherit' }}>{t('footer.home')}</Link>
                {/* {!isContactPage && <li className="nav-item"><Link to="/contact" className="nav-link px-2 text-muted">Contact</Link></li>} */}
            </div>
            <p className="text-center text-muted">© {currentYear} Sponsify</p>
        </footer>
    );
}


export default Footer;