import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchSponsor } from '../../redux/slices/sponsorSlice';
import { fetchProjectsBySponsorId } from '../../redux/slices/projectsBySponsorIdSlice';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function SponsorPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { sponsorId } = useParams();

    const sponsor = useSelector((state) => state.sponsor.sponsor);
    const projects = useSelector((state) => state.projectsBySponsorId.projects);

    useEffect(() => {
        dispatch(fetchProjectsBySponsorId(sponsorId));
        dispatch(fetchSponsor(sponsorId));
    }, [dispatch, sponsorId])

    const projectsSponsored = projects
        .flatMap(sponsorProject => sponsorProject.orders)
        .filter(project => !project.subscription_cancel_at || new Date(project.subscription_cancel_at) > new Date());

    const uniqueProjectsSponsored = Array.from(new Set(projectsSponsored.map(project => project.project_id)))
        .map(id => projectsSponsored.find(project => project.project_id === id));

    const sponsor_address = `${sponsor.address_line_1}${sponsor.address_line_2 ? ' ' + sponsor.address_line_2 : ''} · ${sponsor.postcode} · ${sponsor.city}`;

    return (
        <>
        {/* Hero */}
        <div className="bg-primary px-4 py-5 margin-top-58 text-center text-white">
            <div className="py-3">
                <h1 className="display-5 fw-bold text-uppercase">{sponsor.sponsor_name}</h1> 
            </div>
        </div>

        <div className="container mt-5">
            {projectsSponsored.length > 0 ? (
                <div className="">
                    <div className="text-center mb-5">
                        <img 
                            src={`https://storage.googleapis.com/sponsify/sponsors-logo/${sponsor.logo}`} 
                            className="mb-2" 
                            alt={sponsor.sponsor_name}
                            height="150px"
                            onError={(e) => {
                                e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                            }}
                        />
                    </div>

                    <section className="mt-5" id="location">
                        <h1 className="display-6">{t('sponsors.whereWeAre')}</h1>
                        <hr className="custom-divider mb-3"/>
                        <div className="d-inline-flex align-items-center">
                                <FontAwesomeIcon className="me-2" icon="fa-solid fa-location-dot" />
                                <div className="lead">{sponsor_address}</div>
                        </div>
                    </section>

                    <section className="mt-5" id="about">
                        <h1 className="display-6">{t('projects.projectsWeSponsor')}</h1>
                        <hr className="custom-divider mb-5"/>
                        <div className="row">
                        {uniqueProjectsSponsored?.map((project) => (
                            <div className="col-lg-3 mb-4" key={project.project_id}>
                                <div className="card">
                                    <Link to={`/projects/${project.project_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <img 
                                            src={`https://storage.googleapis.com/sponsify/projects/${project.project_images[0]}`} 
                                            className="card-img-top" 
                                            alt={project.project_name} 
                                            onError={(e) => {
                                                e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                            }}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">{project.project_name}</h5>
                                            <p>
                                                <span className="me-1">
                                                    <img 
                                                        src={`https://storage.googleapis.com/sponsify/grantees-logo/${project.grantee_logo}`} 
                                                        alt={project.project_name}
                                                        height="50px"
                                                        onError={(e) => {
                                                            e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                                        }} 
                                                    />
                                                </span>
                                                {project.grantee_name}
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            ))}
                        </div>
                    </section>
                </div>
            ) : (
                <p>{t('projects.noProjectSponsored')} {sponsor.name}</p>
            )}   
        </div>
        </>
    )

}
  
export default SponsorPage;
  