import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WhatsappContactButton = () => {
	const { t } = useTranslation();

	const phone = '34633307071'
	const text = t('general.whatsappText')
	const whatsappLink = `https://wa.me/${phone}?text=${text}`;

	return (
		<a className="fs-4 link-primary ms-3" href={whatsappLink} target="_blank" rel="noopener noreferrer">
			<FontAwesomeIcon icon="fa-brands fa-whatsapp" />
		</a>
	);
};

export default WhatsappContactButton;
