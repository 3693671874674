import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { useParams } from 'react-router-dom';
import { fetchVoucher } from '../../redux/slices/voucherSlice';
import { redeemVoucher } from '../../redux/slices/redeemVoucherSlice';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';

const socket = io(process.env.REACT_APP_SERVER_URL);

function Voucher() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { voucherId } = useParams();

    const [formData, setFormData] = useState({purchase_value: ''});
    const [status, setStatus] = useState(localStorage.getItem('status') || '');  // State to keep track of the status
    const [icon, setIcon] = useState('');
    const [color, setColor] = useState('');

    const voucher = useSelector((state) => state.voucher.voucher);

    useEffect(() => {
        dispatch(fetchVoucher(voucherId));
        // Listen for voucher status updates
        socket.on('voucherStatusUpdated', (data) => {
            if (data.status === 'approved') {
                setStatus(t('vouchers.approved'));
                localStorage.removeItem('status');
                setIcon('fa-circle-check');
                setColor('text-success');
                setTimeout(() => {
                    setStatus('');
                }, 5000); // Show approved message for 5 seconds
                setFormData({purchase_value: ''});
            } else if (data.status === 'rejected') {
                setStatus(t('vouchers.rejected'));
                localStorage.removeItem('status');
                setIcon('fa-circle-xmark');
                setColor('text-danger');
                setTimeout(() => {
                    setStatus('');
                }, 5000); // Show approved message for 5 seconds
                setFormData({purchase_value: ''});
            }
        });

        return () => {
            socket.off('voucherStatusUpdated');
        };
    }, [dispatch, voucherId, t]);

    const handleChange = (e) => {
        const value = e.target.value;
        setFormData({ 
            ...formData, 
            [e.target.name]: value === '' ? '' : parseFloat(value)
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(redeemVoucher({ voucherId, formData }));
            setStatus(t('vouchers.waitingApproval'));
            localStorage.setItem('status', t('vouchers.waitingApproval'));
            // Emit an event to notify the server
            socket.emit('redeemVoucher', { voucherId, status: 'waiting approval' }); 
        } catch (error) {
            console.error('Error during redemption:', error);
        }
    };

    const expirationDate = (createdAt, validMonths) => {
        const createdDate = new Date(createdAt);
        createdDate.setMonth(createdDate.getMonth() + validMonths);
        return createdDate.toLocaleDateString();  // Format as needed
    };

    const calculateDiscount = (value, discount) => {
        return (value - (value * discount / 100)).toFixed(2);
    }

    const uniqueGrantees = {};
    voucher[0]?.grantees?.forEach(grantee => {
        if (!uniqueGrantees[grantee.grantee_name]) {
            uniqueGrantees[grantee.grantee_name] = grantee.grantee_logo;
        }
    });
    const grantees = Object.keys(uniqueGrantees).map(name => ({
        name,
        logo: uniqueGrantees[name],
    }));

    return (
        <div className="container mt-5">
            <h2 className="mt-5 mb-3">{t('vouchers.voucher')}</h2>
            <hr className="mb-5"/>
            {voucher.length > 0 ? (
                <div className="row">
                    {status === '' ? (
                        <div key={voucher[0].voucher_id} className="col-lg-4">
                        <div className="card text-center mb-4">
                            <div className="card-body">
                            <div className="text-center">
                                    <img 
                                        src={`https://storage.googleapis.com/sponsify/sponsors-logo/${voucher[0].sponsor_logo}`} 
                                        className=""
                                        height="50px" 
                                        alt={voucher[0].sponsor_name}
                                        onError={(e) => {
                                            e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                        }}
                                    />
                                    <p className="small">{voucher[0].sponsor_name}</p>
                                </div>
                                <div className="fs-1 fw-bold">{Math.round(voucher[0].discount)}<span className="fs-5 fw-normal">%</span></div>
                                <div>{t('vouchers.off')}</div>
                                {voucher[0].purchase_over > 0 ? (
                                    <p className="card-text">{t('vouchers.purchasesOver')} €{voucher[0].purchase_over}</p>
                                ) : (
                                    <p className="card-text">{t('vouchers.purchasesOverAnyPrice')}</p>
                                )}

                                <form onSubmit={handleSubmit} className="mb-3">
                                    <div className="input-group mb-3">
                                    <input
                                        className="form-control"
                                        type="number"
                                        min={voucher[0].purchase_over > 0 ? voucher[0].purchase_over : "0.00"}
                                        max="10000.00"
                                        step="0.01"
                                        id="purchase_value"
                                        name="purchase_value"
                                        autoComplete="purchase_value"
                                        placeholder={t('vouchers.inputTotalPrice')}
                                        value={formData.purchase_value}
                                        onChange={handleChange}
                                        required
                                    />
                                    <button className="btn btn-outline-secondary" type="submit">{t('buttons.go')}</button>
                                    </div>
                                </form>

                                {formData.purchase_value && formData.purchase_value >= voucher[0].purchase_over ? (
                                    <p>
                                        {t('vouchers.payOnly')} <br/>
                                        <span className="fs-4 fw-bold">€{calculateDiscount(formData.purchase_value, voucher[0].discount)}</span> <br/>
                                        {t('vouchers.forThisPurchase')}
                                    </p>

                                ) : (
                                    <p></p>
                                )}

                                {voucher[0].valid_months ? (
                                    <p className="card-text small my-0">
                                        {t('vouchers.validAt')} {voucher[0].sponsor_name} {t('general.onThe')} {voucher[0].sponsor_address_line_1} {voucher[0].sponsor_address_line_2} · {voucher[0].sponsor_postcode} · {voucher[0].sponsor_city}<br/>
                                        {t('general.till')} {expirationDate(voucher[0].created_at, voucher[0].valid_months)}
                                    </p>
                                ) : (
                                    <p className="card-text small my-0">{t('vouchers.validIndefinitely')}</p>
                                )}
                                <p className="card-text small">{t('vouchers.notValidOnlineStore')}</p>
                                
                                <hr/>

                                <div className="row">  
                                    {grantees.map((grantee, index) => (
                                        <div key={index} className="col-4">
                                            <img 
                                                src={`https://storage.googleapis.com/sponsify/grantees-logo/${grantee.logo}`} 
                                                className=""
                                                height="50px" 
                                                alt={voucher.sponsor_name}
                                                onError={(e) => {
                                                    e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                                }}
                                            />
                                            <p className="small">{grantee.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    ) : status === t('vouchers.waitingApproval') ? (
                        <div className="text-center mb-2">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <p>{status}</p>
                        </div>
                    ) : (
                        <div className="text-center mb-2">
                        <FontAwesomeIcon className={`${color} fs-3`} icon={`fa-regular ${icon}`} />
                        <p>{status}</p>
                        </div>
                    )}
                </div>
            ) : (
                <p>{t('vouchers.noVouchersFound')}.</p>
            )}
        </div>
    );
}

export default Voucher;